/**
 * Define default values for Feature Flags as a fallback in case Launch Darkly is down or a flag cannot be found
 * Feature Flags are hyphenated keys by default in Launch Darkly but Vue-LD converts them to camelCased keys
 * therefore the Feature Flag values below should be camelCased
 * See this issue for details https://github.com/dashhudson/vue-ld/issues/22.
 *
 * @exports featureFlags
 * @category Utils
 */

// rff: centralInboxesVisibleForBrokers https://watchtower.atlassian.net/browse/BPL-1496
export const centralInboxesVisibleForBrokers = 'centralInboxesVisibleForBrokers';
// rff:differenceFromCurrentOnProductComparison https://watchtower.atlassian.net/browse/BPL-1435
export const differenceFromCurrentOnProductComparison = 'differenceFromCurrentOnProductComparison';
// rff:effectiveDateForCoverages https://watchtower.atlassian.net/browse/BPL-853
export const effectiveDateForCoverages = 'effectiveDateForCoverages';
// rff:inFlightAlternatives https://watchtower.atlassian.net/browse/BPL-1181
export const inFlightAlternatives = 'inFlightAlternatives';
// rff:npcTemplate https://watchtower.atlassian.net/browse/BPL-1115
export const npcTemplate = 'npcTemplate';
// rff:npcTemplateAccident https://watchtower.atlassian.net/browse/BPL-1178
export const npcTemplateAccident = 'npcTemplateAccident';
// rff:npcTemplateBdl https://watchtower.atlassian.net/browse/BPL-852
export const npcTemplateBdl = 'npcTemplateBdl';
// rff:npcTemplateCi https://watchtower.atlassian.net/browse/BPL-1119
export const npcTemplateCi = 'npcTemplateCi';
// rff:renewalDetailStep https://watchtower.atlassian.net/browse/BPL-918
export const renewalDetailStep = 'renewalDetailStep';
// rff:renewalEditor https://watchtower.atlassian.net/browse/BPL-1319
export const renewalEditor = 'renewalEditor';
// rff:truncatedRateValues https://watchtower.atlassian.net/browse/BPL-1495
export const truncatedRateValues = 'truncatedRateValues';
// rff:truncateStopLossRateValues https://watchtower.atlassian.net/browse/BPL-1494
export const truncateStopLossRateValues = 'truncateStopLossRateValues';
// rff:brokerUiSsPaginatedClientTables https://watchtower.atlassian.net/browse/LC-106
export const brokerUiSsPaginatedClientTables = 'brokerUiSsPaginatedClientTables';
// rff:brokerUiSsPaginatedTableEnhancements https://watchtower.atlassian.net/browse/LC-105
export const brokerUiSsPaginatedTableEnhancements = 'brokerUiSsPaginatedTableEnhancements';
// rff:brokerUserDeactivation https://watchtower.atlassian.net/browse/LC-601
export const brokerUserDeactivation = 'brokerUserDeactivation';
// rff:editRenewalScheduleContacts https://watchtower.atlassian.net/browse/BPL-1790
export const editRenewalScheduleContacts = 'editRenewalScheduleContacts';
// rff:manageRenewalSchedules https://watchtower.atlassian.net/browse/BPL-1989
export const manageRenewalSchedules = 'manageRenewalSchedules';
// rff:localRenewalSchedules https://watchtower.atlassian.net/browse/BPL-2166
export const localRenewalSchedules = 'localRenewalSchedules';
// rff:markSoldOpportunities https://watchtower.atlassian.net/browse/BPL-2300
export const markSoldOpportunities = 'markSoldOpportunities';
// rff:editRenewalScheduleDueDate https://watchtower.atlassian.net/browse/BPL-2423
export const editRenewalScheduleDueDate = 'editRenewalScheduleDueDate';
// rff:bypassDocumentRequirementRenewals https://watchtower.atlassian.net/browse/BPL-2423
export const bypassDocumentRequirementRenewals = 'bypassDocumentRequirementRenewals';
// rff:showQuickFilters https://watchtower.atlassian.net/browse/BPL-2555
export const markSoldOpportunitiesQuickFilters = 'markSoldOpportunitiesQuickFilters';
// rff:showCarrierLogoInExcel https://watchtower.atlassian.net/browse/BPL-2564
export const showCarrierLogoInExcel = 'showCarrierLogoInExcel';
// rff:inviteFinalist https://watchtower.atlassian.net/browse/BPL-2563
export const inviteFinalist = 'inviteFinalist';
// rff:brokerUiRenewalShortcutSupport https://watchtower.atlassian.net/browse/LC-663
export const brokerUiRenewalShortcutSupport = 'brokerUiRenewalShortcutSupport';
// rff:markSoldOpportunitiesMyAllClients https://watchtower.atlassian.net/browse/BPL-2422
export const markSoldOpportunitiesMyAllClients = 'markSoldOpportunitiesMyAllClients';
// rff:benefitCalloutComments https://watchtower.atlassian.net/browse/BPL-2852
export const benefitCalloutComments = 'benefitCalloutComments';
// rff:brokerQuoteSubmissionEdits https://watchtower.atlassian.net/browse/BPL-2994
export const brokerQuoteSubmissionEdits = 'brokerQuoteSubmissionEdits';
// rff: benefitCalloutCommentsRead https://watchtower.atlassian.net/browse/BPL-3035
export const inviteSingleCarrierUser = 'inviteSingleCarrierUser';
// rff: skipMarkSoldConfirmation https://watchtower.atlassian.net/browse/BPL-322
export const skipMarkSoldConfirmation = 'skipMarkSoldConfirmation';
// rff: editRenewalScheduleCollectionDate https://watchtower.atlassian.net/browse/BPL-3243
export const editRenewalScheduleCollectionDate = 'editRenewalScheduleCollectionDate';
// rff: closeProjectProduct https://watchtower.atlassian.net/browse/BPL-3346
export const closeProjectProduct = 'closeProjectProduct';
// rff: currentCoverageMarkSold https://watchtower.atlassian.net/browse/BPL-3364
export const currentCoverageMarkSold = 'currentCoverageMarkSold';
// rff: createRenewalWithoutSendingCarrierInvitationEmail https://watchtower.atlassian.net/browse/BPL-3403
export const createRenewalWithoutSendingCarrierInvitationEmail = 'createRenewalWithoutSendingCarrierInvitationEmail';
// rff: coverageTrackerResponseColumn https://watchtower.atlassian.net/browse/BPL-3410
export const coverageTrackerResponseColumn = 'coverageTrackerResponseColumn';
// rff: truncateClientName https://watchtower.atlassian.net/browse/BPL-3397
export const truncateClientName = 'truncateClientName';
// rff: noWelcomeBanner https://watchtower.atlassian.net/browse/BPL-3407
export const noWelcomeBanner = 'noWelcomeBanner';
// rff: rateGuaranteeApi https://watchtower.atlassian.net/browse/BPL-3443
export const rateGuaranteeApi = 'rateGuaranteeApi';
// rff: noWelcomeBanner https://watchtower.atlassian.net/browse/BPL-3459
export const renewalsWithoutCurrentCoverages = 'renewalsWithoutCurrentCoverages';
// rff: globalSidebarNavigation https://watchtower.atlassian.net/browse/BPL-3479
export const globalSidebarNavigation = 'globalSidebarNavigation';
// rff: gtmReviewCoverageDetails https://watchtower.atlassian.net/browse/BPL-3522
export const gtmReviewCoverageDetails = 'gtmReviewCoverageDetails';
// rff: selfServicePlanAlternatives https://watchtower.atlassian.net/browse/BPL-3658
export const selfServicePlanAlternatives = 'selfServicePlanAlternatives';
// rff: documentTab https://watchtower.atlassian.net/browse/BPL-3712
export const documentTab = 'documentTab';
// rff: documentPrivacy https://watchtower.atlassian.net/browse/BPL-3742
export const documentPrivacy = 'documentPrivacy';
// rff: npcSelfService https://watchtower.atlassian.net/browse/BPL-3783
export const npcSelfService = 'npcSelfService';
// rff: gtmRenewalSelfService https://watchtower.atlassian.net/browse/BPL-3822
export const gtmRenewalSelfService = 'gtmRenewalSelfService';

// These defaults will be set instead of the defaultFlagValues if a user is in the dev or test environment
// this allows devs to manually control feature toggles while developing or testing
export const developmentFlagValues = {
  [brokerUiSsPaginatedClientTables]: true,
  [brokerUiSsPaginatedTableEnhancements]: true, // The brokerUiSsPaginatedClientTables flag must be enabled in order for this flag to work
  [brokerUserDeactivation]: true,
  [bypassDocumentRequirementRenewals]: false,
  [centralInboxesVisibleForBrokers]: '20', // 'all', 'none', or comma separated e.g. '1, 2, 3,'
  [differenceFromCurrentOnProductComparison]: true,
  [editRenewalScheduleContacts]: true,
  [editRenewalScheduleDueDate]: true,
  [effectiveDateForCoverages]: true,
  [inFlightAlternatives]: true,
  [inviteFinalist]: true,
  [localRenewalSchedules]: true,
  [manageRenewalSchedules]: true,
  [markSoldOpportunities]: true,
  [markSoldOpportunitiesQuickFilters]: true,
  [markSoldOpportunitiesMyAllClients]: true,
  [npcTemplate]: true,
  [npcTemplateAccident]: true,
  [npcTemplateBdl]: true,
  [npcTemplateCi]: true,
  [renewalDetailStep]: true,
  [renewalEditor]: true,
  [showCarrierLogoInExcel]: false,
  [truncatedRateValues]: true,
  [truncateStopLossRateValues]: true,
  [brokerUiRenewalShortcutSupport]: true,
  [benefitCalloutComments]: true,
  [brokerQuoteSubmissionEdits]: true,
  [inviteSingleCarrierUser]: true,
  [skipMarkSoldConfirmation]: true,
  [editRenewalScheduleCollectionDate]: true,
  [closeProjectProduct]: 'request',
  [currentCoverageMarkSold]: true,
  [createRenewalWithoutSendingCarrierInvitationEmail]: true,
  [coverageTrackerResponseColumn]: true,
  [truncateClientName]: true,
  [noWelcomeBanner]: true,
  [rateGuaranteeApi]: true,
  [renewalsWithoutCurrentCoverages]: true,
  [globalSidebarNavigation]: false,
  [gtmReviewCoverageDetails]: false,
  [selfServicePlanAlternatives]: false,
  [documentTab]: true,
  [documentPrivacy]: true,
  [npcSelfService]: false,
  [gtmRenewalSelfService]: true,
};

/**
 * Default Launch Darkly Flags (for Production, Staging and Features or if Launch Darkly can't be reached)
 * Keys match the feature flag names and values match the actual default flag values that we want to set.
 * We will need to update this object each time a new flag is added to Launch Darkly
 * and also be sure to use safe defaults here in case we cannot communicate with Launch Darkly so that
 * we do not accidentally enable a feature in production.
 */
export const defaultFlagValues = {
  [brokerUiSsPaginatedClientTables]: true,
  [brokerUiSsPaginatedTableEnhancements]: false, // The brokerUiSsPaginatedClientTables flag must be enabled in order for this flag to work
  [brokerUserDeactivation]: false,
  [bypassDocumentRequirementRenewals]: false,
  [centralInboxesVisibleForBrokers]: 'none',
  [differenceFromCurrentOnProductComparison]: false,
  [editRenewalScheduleContacts]: false,
  [editRenewalScheduleDueDate]: false,
  [effectiveDateForCoverages]: false,
  [inFlightAlternatives]: false,
  [inviteFinalist]: false,
  [localRenewalSchedules]: false,
  [manageRenewalSchedules]: false,
  [markSoldOpportunities]: false,
  [markSoldOpportunitiesQuickFilters]: false,
  [markSoldOpportunitiesMyAllClients]: false,
  [npcTemplate]: true,
  [npcTemplateAccident]: false,
  [npcTemplateBdl]: false,
  [npcTemplateCi]: false,
  [renewalDetailStep]: false,
  [renewalEditor]: false,
  [showCarrierLogoInExcel]: false,
  [truncatedRateValues]: false,
  [truncateStopLossRateValues]: false,
  [brokerUiRenewalShortcutSupport]: false,
  [benefitCalloutComments]: false,
  [brokerQuoteSubmissionEdits]: false,
  [inviteSingleCarrierUser]: false,
  [skipMarkSoldConfirmation]: false,
  [editRenewalScheduleCollectionDate]: false,
  [closeProjectProduct]: 'none',
  [currentCoverageMarkSold]: false,
  [createRenewalWithoutSendingCarrierInvitationEmail]: false,
  [coverageTrackerResponseColumn]: false,
  [truncateClientName]: false,
  [noWelcomeBanner]: false,
  [rateGuaranteeApi]: false,
  [renewalsWithoutCurrentCoverages]: false,
  [globalSidebarNavigation]: false,
  [gtmReviewCoverageDetails]: false,
  [selfServicePlanAlternatives]: false,
  [documentTab]: false,
  [documentPrivacy]: false,
  [npcSelfService]: false,
  [gtmRenewalSelfService]: true,
};
